<template>
  <b-row>
    <b-col>
      <div class="center-content text-center">
        <h1 class="text-60">
          404
        </h1>
        <p class="text-36 subheading mb-3">Error!</p>
        <p class="mb-5 text-muted text-18">
          ¡Lo siento! La página que buscaba no existe.
        </p>
        <a class="btn btn-lg btn-skyblue btn-rounded" href="/"
          >Ir a Inicio</a
        >
      </div>
    </b-col>
  </b-row>
</template>
<script>
export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: '404 Not Found',
  },
};
</script>
<style scoped>
.btn-skyblue
{
  color: #fff;
  background-color: #61c6f2;
}
.btn-skyblue:hover
{
  box-shadow: 0px 5px 20px #61c6f2;
}
.center-content{
  height: 100vh;
  padding: 120px 0;
}
.center-content h1{
  font-weight: 800;
  margin-bottom: 16px;
  line-height: 1;
}
.center-content .subheading{
  font-weight: 800;
}
</style>
